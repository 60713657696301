.connect-form {
    margin: 0 0 74px;
    &__form {
        display: none;

        @media (max-width: $mobile-max) {
            display: block;
            width: 230px;
            margin: 0 auto;
        }

        &-inner {
            &.has-error {
                input {
                    border-color: #FE5616;
                    background: linear-gradient(0deg, rgba(254, 86, 22, 0.2), rgba(254, 86, 22, 0.2)), #252525;
                }

                .connect-form__error-inner {
                    display: flex;
                }
            }

            &.loading {
                .connect-form__submit {
                    filter: grayscale(0.5);
                }
            }
        }

    }

    &__input-inner {
        position: relative;
    }

    input {
        display: block;
        width: 100%;
        height: 60px;
        margin-bottom: 40px;
        padding: 0 18px;
        border: 2px solid $violet;
        background: #252525;
        color: #787878;
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
        border-radius: 12px;
    }

    .cat  {
        position: absolute;
        left: -16px;
        top: 85px;
        width: 117px;
        height: 267px;
    }

    &__submit  {
        position: relative;
        width: 230px;
        height: 58px;
        font-size: 18px;
        line-height: 24px;
        background-color: transparent;
        border: 0;

        .text  {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 700;
            color: $dark-text;
        }

        svg  {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            fill: $light-blue;
        }

        &::before  {
            content: '';
            position: absolute;
            top: -8px;
            left: -18px;
            width: 262px;
            height: 83px;
            background-image: url('../img/button-bg.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &__dont-know-code {
        display: none;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        border-bottom: 1px solid #787878;
        color: #787878;

        @media (max-width: $mobile-max) {
            display: block;
            margin: 32px auto 0;
            text-align: center;
        }
    }

    &__error-inner {
        display: none;
        position: absolute;
        top: calc(100% + 2px);
        width: 100%;
        left: 50%;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        transform: translateX(-50%);
    }

    &__error {
        font-size: 16px;
        line-height: 28px;
        color: #FE5616;
        font-weight: 700;

        &-icon {
            width: 25px;
            height: 25px;
            margin: 0 4px 0 0;
        }
    }
}
