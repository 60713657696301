.subscribe-form {
    margin-bottom: 445px;

    @media (max-width: $desktop-xxl-max) {
        margin-bottom: 300px;
    }

    @media (max-width: $desktop-xl-max) {
        margin-bottom: 113px;
    }

    @media (max-width: $desktop-lg-max) {
        margin-bottom: 113px;
    }

    @media (max-width: $desktop-md-max) {
        margin-bottom: 74px;
    }

    @media (max-width: $desktop-sm-max) {
        margin-bottom: 92px;
    }

    @media (max-width: $mobile-max) {
        margin-bottom: 50px;
    }

    &__error-inner {
        display: none;
        position: absolute;
        top: calc(100% + 15px);
        width: 100%;
        left: 50%;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        transform: translateX(-50%);
    }

    &__error {
        font-size: 16px;
        line-height: 28px;
        color: #FE5616;

        &-icon {
            width: 25px;
            height: 25px;
            margin: 0 4px 0 0;
        }
    }

    &__form-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1550px;
        margin: 140px auto 0;
        transform: translateX(-45px);
        font-size: 38px;
        line-height: 50px;
        font-weight: 700;

        @media (max-width: $desktop-xxl-max) {
            width: 1301px;
            margin-top: 113px;
            font-size: 32px;
            line-height: 42px;
            transform: translateX(80px);
        }

        @media (max-width: $desktop-xl-max) {
            width: 780px;
            margin-top: 68px;
            transform: translateX(-48px);
            font-size: 20px;
            line-height: 26px;
        }

        @media (max-width: $desktop-lg-max) {
            transform: translateX(-31px);
        }

        @media (max-width: $desktop-md-max) {
            width: 542px;
            margin-top: 36px;
            font-size: 14px;
            line-height: 18px;
            transform: translateX(-34px);
        }

        @media (max-width: $desktop-sm-max) {
            margin-top: 48px;
        }

        @media (max-width: $mobile-max) {
            flex-direction: column;
            width: 100%;
            margin-top: 42px;
            transform: translateX(0);
        }

        &.subscribe-success {
            button {
                background: #fff;
                color: $violet;
            }

            .subscribe-form__success-icon {
                display: block;
            }
        }

        button {
            width: 644px;
            height: 140px;
            background: $light-blue;
            text-align: center;
            border: 0;
            border-radius: 28px;
            cursor: pointer;
            color: $dark-text;
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

            @media (max-width: $desktop-xxl-max) {
                width: 537px;
                height: 117px;
                border-radius: 23px;
            }

            @media (max-width: $desktop-xl-max) {
                width: 322px;
                height: 70px;
                border-radius: 14px;
            }

            @media (max-width: $desktop-md-max) {
                width: 224px;
                height: 48px;
                border-radius: 10px;
            }

            @media (max-width: $mobile-max) {
                position: relative;
                width: 219px;
                border-radius: 13px;
            }

            .subscribe-form__success-icon {
                display: none;
                width: 35px;
                height: 35px;
                margin-left: 12px;

                @media (max-width: $desktop-md-max) {
                    width: 24px;
                    height: 24px;
                }
            }

            .subscribe-form__submit-text {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                color: inherit;

                span {
                    color: inherit;
                }
            }

            &:hover {
                background: #fff;
                color: $violet;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 248px;
                height: 280px;
                right: -110px;
                background-image: url('../img/hat.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                pointer-events: none;

                @media (max-width: $desktop-xxl-max) {
                    right: -99px;
                    width: 224px;
                    height: 252px;
                }

                @media (max-width: $desktop-xl-max) {
                    right: -53px;
                    width: 124px;
                    height: 140px;
                }

                @media (max-width: $desktop-md-max) {
                    right: -38px;
                    width: 86px;
                    height: 97px;
                }

                @media (max-width: $mobile-max) {
                    width: 84px;
                    height: 95px;
                    right: -36px;
                }
            }
        }

        input {
            width: 100%;
            height: calc(100% - 4px);
            padding: 0 4px;
            background: transparent;
            color: rgba(255, 255, 255, 0.3);
            border: none;
            text-align: center;
            flex-grow: 1;
        }
    }

    &__inner-input {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 876px;
        height: 140px;
        padding: 0 20px;
        margin-right: 40px;
        background: #252525;
        color: rgba(255, 255, 255, 0.3);
        border-radius: 28px;
        border: 1px solid #252525;
        text-align: center;

        @media (max-width: $desktop-xxl-max) {
            width: 731px;
            height: 117px;
            margin-right: 33px;
            border-radius: 23px;
        }

        @media (max-width: $desktop-xl-max) {
            width: 438px;
            height: 70px;
            margin-right: 20px;
            border-radius: 14px;
        }

        @media (max-width: $desktop-md-max) {
            width: 304px;
            height: 48px;
            margin-right: 14px;
            border-radius: 10px;
        }

        @media (max-width: $mobile-max) {
            width: 297px;
            margin-right: 0;
            margin-bottom: 56px;
        }

        &.has-error {
            border-color: #FE5616;
            background: linear-gradient(0deg, rgba(254, 86, 22, 0.2), rgba(254, 86, 22, 0.2)), #252525;
            .subscribe-form__error-inner {
                display: flex;
            }
        }
    }

    &__download {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        text-decoration: none;
        outline: none;
        font-size: 44px;
        line-height: 57px;
        z-index: 10;

        @media (max-width: $desktop-xxl-max) {
            margin-left: 119px;
            font-size: 36px;
            line-height: 47px;
        }

        @media (max-width: $desktop-xl-max) {
            margin-left: -20px;
            font-size: 24px;
            line-height: 31px;
        }

        @media (max-width: $desktop-lg-max) {
            margin-left: 0;
            font-size: 20px;
            line-height: 26px;
        }

        @media (max-width: $desktop-md-max) {
            margin-left: -15px;
            font-size: 16px;
            line-height: 20px;
        }

        @media (max-width: $mobile-max) {
            margin-left: 0;
            font-size: 14px;
            line-height: 18px;
        }

        svg {
            width: 78px;
            height: 78px;
            margin-right: 4px;
            transition: transform 0.3s ease-in-out;

            @media (max-width: $desktop-xxl-max) {
                width: 64px;
                height: 64px;
            }

            @media (max-width: $desktop-xl-max) {
                width: 42px;
                height: 42px;
            }

            @media (max-width: $desktop-lg-max) {
                width: 38px;
                height: 38px;
            }

            @media (max-width: $desktop-md-max) {
                width: 28px;
                height: 28px;
            }

            @media (max-width: $mobile-max) {
                width: 27px;
                height: 27px;
            }
        }

        &:hover {
            svg {
                transform: scale(1.2);
            }
        }
    }
}


