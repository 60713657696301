.header {
    position: relative;
    z-index: 101;
    margin-bottom: 200px;

    @media (max-width: $desktop-xxl-max) {
        margin-bottom: 277px;
    }

    @media (max-width: $desktop-xl-max) {
        margin-bottom: 38px;
    }

    @media (max-width: $desktop-lg-max) {
        margin-bottom: 43px;
    }

    @media (max-width: $desktop-md-max) {
        margin-bottom: 29px;
    }

    @media (max-width: $desktop-sm-max) {
        margin-bottom: 48px;
    }

    @media (max-width: $mobile-max) {
        margin-bottom: 29px;
        z-index: 10;
    }

    .landing__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        position: relative;
        width: 348px;
        height: 208px;

        @media (max-width: $desktop-xxl-max) {
            width: 337px;
            height: 202px;
        }

        @media (max-width: $desktop-xl-max) {
            width: 209px;
            height: 125px;
        }

        @media (max-width: $desktop-lg-max) {
            width: 209px;
            height: 125px;
        }

        @media (max-width: $desktop-md-max) {
            width: 122px;
            height: 73px;
        }

        @media (max-width: $mobile-max) {
            width: 80px;
            height: 48px;
        }

        svg {
            width: 100%;
            height: 100%;
            display: block;
        }

        span {
            position: absolute;
            left: 100%;
            top: 30px;
            font-weight: 700;
            opacity: 0.6;
            font-size: 36px;
            line-height: 46px;

            @media (max-width: $desktop-xl-max) {
                top: 17px;
                font-size: 24px;
                line-height: 31px;
            }

            @media (max-width: $desktop-md-max) {
                top: 14px;
                font-size: 16px;
                line-height: 22px;
            }

            @media (max-width: $desktop-sm-max) {
                font-size: 14px;
                line-height: 14px;
                top: 15px;
            }

            @media (max-width: $mobile-max) {
                font-size: 10px;
                line-height: 14px;
                top: 10px;
            }
        }
    }

    &__email  {
        cursor: pointer;
        font-weight: 700;
        font-size: 48px;
        line-height: 62px;

        @media (max-width: $desktop-xxl-max) {
            font-size: 36px;
            line-height: 46px;
        }

        @media (max-width: $desktop-xl-max) {
            font-size: 24px;
            line-height: 32px;
        }

        @media (max-width: $desktop-lg-max) {
            line-height: 32px;
            font-size: 24px;
        }

        @media (max-width: $desktop-md-max) {
            font-size: 16px;
            line-height: 20px;
        }

        @media (max-width: $mobile-max) {
            display: none;
        }
    }

    &__info {
        display: none;

        @media (max-width: $mobile-max) {
            display: block;
            font-size: 22px;
            line-height: 28px;
            font-weight: 700;
        }
    }
}
