.video-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);

    .video-holder {
        position: relative;
        width: 1058px;

        @media (min-width: $desktop-xl-min) {
            width: 70%;
        }

        @media (max-width: $desktop-sm-max) {
            width: 90%;
        }

        &::before {
            content: '';
            width: 150%;
            height: 180%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('../img/video-blur.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: 0;
            pointer-events: none;

            @media (max-width: $mobile-max) {
                width: 150%;
                height: 600px;
            }
        }
    }

    .video-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background: linear-gradient(0deg, #12214D, #12214D);

        @keyframes rotate {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;

            svg {
                width: 100%;
                height: 100%;
                transform-origin: center;
                animation: rotate 1s linear infinite;
            }
        }
    }

    &__logo {
        display: none;

        @media (max-width: $mobile-max) {
            display: block;
            position: relative;
            z-index: 2;
            width: 91px;
            height: 55px;
            margin-bottom: 40px;
        }

        svg {
            width: 100%;
            height: 100%;
        }

        span {
            position: absolute;
            top: 10px;
            left: 100%;
            font-size: 12px;
            line-height: 15px;
            opacity: 0.6;
        }
    }

    video {
        position: relative;
        width: 100%;
        border-radius: 12px;
    }
}

