body, html, body * {
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
    color: #fff;
    box-sizing: border-box;
}

html, body {
    overflow-x: hidden;
}

body.fixed {
    height: 100vh;
    overflow: hidden;
}

.error-holder {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    padding: 15px;
    border-radius: 20px;
    background-color: $violet;
    color: #fff;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    box-shadow: 1px 1px 8px 5px rgb(0, 0, 0, 0.5);
    z-index: 200;
}

body.code-popup-open {
    .header {
        z-index: 101;
    }
}
