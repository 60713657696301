.landing {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    padding-top: 90px;
    background-image: url('../img/main-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    @media (max-width: $desktop-xl-max) {
        padding-top: 43px;
    }

    @media (max-width: $desktop-md-max) {
        padding-top: 26px;
    }

    &__container {
        width: calc(5120px - 1002px * 2);
        margin: 0 auto;

        @media (max-width: $desktop-xxl-max) {
            width: calc(3840px - 500px * 2);
        }

        @media (max-width: $desktop-xl-max) {
            width: calc(2560px - 500px * 2);
        }

        @media (max-width: $desktop-lg-max) {
            width: calc(1920px - 190px * 2);
        }

        @media (max-width: $desktop-md-max) {
            width: calc(1200px - 100px * 2);
        }

        @media (max-width: $desktop-sm-max) {
            width: calc(100% - 20px * 2);
        }
    }

    &__first-screen {

        @media (max-width: $mobile-max) {
            margin-bottom: 95px;
        }

        .landing__container {
            display: flex;
            justify-content: space-between;

            @media (max-width: $mobile-max) {
                flex-direction: column;
                padding: 0;
                align-items: center;
                justify-content: center;
            }
        }

        .info {
            width: 978px;
            margin-top: 267px;

            @media (max-width: $desktop-xxl-max) {
                width: 830px;
                margin-top: 194px;
            }

            @media (max-width: $desktop-xl-max) {
                width: 506px;
                margin-top: 126px;
            }

            @media (max-width: $desktop-lg-max) {
                width: 506px;
                margin-top: 128px;
            }

            @media (max-width: $desktop-md-max) {
                width: 362px;
                margin-top: 50px;
            }

            @media (max-width: $desktop-sm-max) {
                width: 266px;
                margin-top: 32px;
            }

            @media (max-width: $mobile-max) {
                width: 312px;
                margin-bottom: 76px;
                margin-top: 0;
            }

            h1 {
                margin-bottom: 36px;
                font-size: 94px;
                line-height: 122px;

                @media (max-width: $desktop-xxl-max) {
                    margin-bottom: 30px;
                    font-size: 80px;
                    line-height: 104px;
                }

                @media (max-width: $desktop-xl-max) {
                    margin-bottom: 20px;
                    font-size: 48px;
                    line-height: 62px;
                }

                @media (max-width: $desktop-md-max) {
                    margin-bottom: 12px;
                    font-size: 24px;
                    line-height: 32px;
                }

                @media (max-width: $desktop-sm-max) {
                    margin-bottom: 12px;
                }

                @media (max-width: $mobile-max) {
                    display: none;
                }
            }

            .text {
                font-size: 38px;
                line-height: 48px;

                @media (max-width: $desktop-xxl-max) {
                    font-size: 32px;
                    line-height: 42px;
                }

                @media (max-width: $desktop-xl-max) {
                    font-size: 20px;
                    line-height: 26px;
                }

                @media (max-width: $desktop-lg-max) {
                    font-size: 20px;
                    line-height: 26px;
                }

                @media (max-width: $desktop-md-max) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (max-width: $mobile-max) {
                    text-align: center;
                }
            }
        }

        .video {
            position: relative;
            width: 1500px;
            height: 1027px;

            @media (max-width: $desktop-xxl-max) {
                width: 1321px;
                height: 904px;
            }

            @media (max-width: $desktop-xl-max) {
                width: 811px;
                height: 555px;
            }

            @media (max-width: $desktop-lg-max) {
                width: 811px;
                height: 555px;
            }

            @media (max-width: $desktop-md-max) {
                width: 449px;
                height: 307px;
            }

            @media (max-width: $desktop-sm-max) {
                width: 398px;
                height: 272px;
            }

            @media (max-width: $mobile-max) {
                width: 310px;
                height: 212px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2300px;
                height: 1800px;
                transform: translate(-50%, -50%);
                background-image: url('../img/video-blur.png');
                background-position: center;
                background-size: cover;
                background-repeat: repeat;
                pointer-events: none;

                @media (max-width: $desktop-xxl-max) {
                    width: 1900px;
                    height: 1600px;
                }

                @media (max-width: $desktop-xl-max) {
                    width: 1600px;
                    height: 1300px;
                }

                @media (max-width: $desktop-lg-max) {
                    width: 1329px;
                    height: 999px;
                }

                @media (max-width: $desktop-md-max) {
                    width: 800px;
                    height: 600px;
                }

                @media (max-width: $mobile-max) {
                    display: none;
                }
            }

            button {
                position: absolute;
                top: 53%;
                left: 43%;
                width: 147px;
                height: 147px;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $light-blue;
                backdrop-filter: blur(10px);
                transform-origin: center;
                transform: rotate(45deg) translate(-50%, -50%);
                border: 0;
                border-radius: 15px;
                cursor: pointer;
                transition: opacity 0.3s ease-in-out;

                @media (max-width: $desktop-xxl-max) {
                    top: 53%;
                    left: 43%;
                    width: 129px;
                    height: 129px;
                    border-radius: 15px;
                }

                @media (max-width: $desktop-xl-max) {
                    width: 78px;
                    height: 78px;
                    border-radius: 13px;
                }

                @media (max-width: $desktop-md-max) {
                    top: 52%;
                    left: 44%;
                    width: 50px;
                    height: 50px;
                    border-radius: 14px;
                }

                @media (max-width: $desktop-sm-max) {
                    top: 53%;
                    left: 45%;
                    width: 44px;
                    height: 44px;
                    border-radius: 14px;
                }

                @media (max-width: $mobile-max) {
                    width: 38px;
                    height: 38px;
                    top: 54%;
                    left: 42%;
                }

                &:hover {
                    opacity: 0.8;
                }

                svg {
                    transform: rotate(-45deg) translateX(10%);
                    transform-origin: center;
                    width: 100px;
                    height: 100px;

                    @media (max-width: $desktop-xxl-max) {
                        width: 70px;
                        height: 70px;
                    }

                    @media (max-width: $desktop-xl-max) {
                        width: 45px;
                        height: 45px;
                    }

                    @media (max-width: $desktop-md-max) {
                        width: 30px;
                        height: 30px;
                    }

                    @media (max-width: $desktop-sm-max) {
                        width: 22px;
                        height: 22px;
                    }

                    @media (max-width: $mobile-max) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .top-balloons {
                position: absolute;
                width: 1899px;
                height: 1435px;
                top: -508px;
                left: -861px;
                z-index: 2;
                transform: rotate(-360deg);
                pointer-events: none;

                @media (max-width: $desktop-xxl-max) {
                    width: 1482px;
                    height: 790px;
                    top: -408px;
                    left: -650px;
                }

                @media (max-width: $desktop-xl-max) {
                    width: 941px;
                    height: 705px;
                    top: -254px;
                    left: -409px;
                }

                @media (max-width: $desktop-md-max) {
                    width: 545px;
                    height: 400px;
                    top: -151px;
                    left: -241px;
                }

                @media (max-width: $desktop-sm-max) {
                    width: 523px;
                    height: 400px;
                    top: -155px;
                    left: -221px;
                }

                @media (max-width: $mobile-max) {
                    width: 322px;
                    height: 241px;
                    top: -99px;
                    left: -153px;
                    transform: rotate(180deg);
                }
            }

            .bottom-balloons {
                position: absolute;
                width: 2122px;
                height: 2122px;
                bottom: -1120px;
                right: -995px;
                z-index: 2;
                transform: rotate(-360deg);
                pointer-events: none;

                @media (max-width: $desktop-xxl-max) {
                    width: 1473px;
                    height: 1104px;
                    bottom: -423px;
                    right: -555px;
                }

                @media (max-width: $desktop-xl-max) {
                    width: 906px;
                    height: 994px;
                    bottom: -545px;
                    right: -300px;
                }

                @media (max-width: $desktop-md-max) {
                    width: 468px;
                    height: 300px;
                    bottom: -54px;
                    right: -133px;
                }

                @media (max-width: $desktop-sm-max) {
                    width: 451px;
                    height: 300px;
                    bottom: -72px;
                    right: -123px;
                }

                @media (max-width: $mobile-max) {
                    width: 327px;
                    height: 327px;
                    bottom: -137px;
                    right: -116px;
                }
            }

            &.active {
                button {
                    display: none;
                }
            }
        }

        .stub {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom center;
        }

        video {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            overflow: hidden;
        }
    }
}
