.landing {
    &__slider {
        position: relative;
        margin-bottom: 310px;

        @media (max-width: $desktop-xxl-max) {
            margin-bottom: 193px;
        }

        @media (max-width: $desktop-xl-max) {
            margin-bottom: 32px;
        }

        @media (max-width: $desktop-lg-max) {
            margin-bottom: 32px;
        }

        @media (max-width: $desktop-md-max) {
            margin-bottom: 8px;
        }

        @media (max-width: $desktop-sm-max) {
            margin-bottom: 12px;
        }

        @media (max-width: $mobile-max) {
            margin-bottom: 50px;
        }

        .swiper {
            padding-bottom: 50px;

            @media (max-width: $mobile-max) {
                padding-bottom: 0;
            }
        }

        .swiper-wrapper {
            align-items: center;
            padding-bottom: 100px;
            padding-top: 100px;

            @media (max-width: $desktop-lg-max) {
                padding-bottom: 50px;
                padding-top: 50px;
            }
        }

        &::after {
            content: '';
            width: 2237px;
            height: 1782px;
            position: absolute;
            left: 50%;
            bottom: -261px;
            transform: translateX(-50%);
            background-image: url('../img/video-blur.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            @media (max-width: $desktop-xxl-max) {
                width: 1521px;
                height: 1223px;
                bottom: -149px;
            }

            @media (max-width: $desktop-lg-max) {
                width: 1329px;
                height: 999px;
                bottom: -190px;
            }

            @media (max-width: $desktop-md-max) {
                width: 658px;
                height: 499px;
                bottom: -35px;
            }

            @media (max-width: $mobile-max) {
                bottom: auto;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 497px;
                height: 449px;
            }
        }

        .swiper-slide {
            position: relative;
            opacity: 0.4;
            width: 1428px;
            height: 861px;
            transform: scale(0.83);
            transition: opacity 0.3s ease-in-out;

            @media (max-width: $desktop-xxl-max) {
                width: 1180px;
                height: 712px;
            }

            @media (max-width: $desktop-xl-max) {
                width: 826px;
                height: 498px;
            }

            @media (max-width: $desktop-md-max) {
                width: 443px;
                height: 267px;
            }

            @media (max-width: $mobile-max) {
                width: 312px;
                height: 188px;
                transform: scale(0.93);
            }

            &.swiper-slide-active {
                transform: scale(1);
                flex-shrink: 0;
                opacity: 1;

                .top {
                    opacity: 1;
                }
            }

            .top {
                position: absolute;
                left: 50%;
                top: 0;
                max-width: 301px;
                max-height: 176px;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;

                @media (max-width: $desktop-xxl-max) {
                    max-width: 249px;
                    max-height: 146px;
                }

                @media (max-width: $desktop-xl-max) {
                    max-width: 174px;
                    max-height: 102px;
                }

                @media (max-width: $desktop-md-max) {
                    width: 93px;
                    height: 55px;
                }

                @media (max-width: $mobile-max) {
                    width: 74px;
                    height: 44px;
                }
            }

            .main {
                width: 100%;
                height: 100%;

                img {
                    border-radius: 20px;

                    @media (max-width: $desktop-md-max) {
                        border-radius: 12px;
                    }

                    @media (max-width: $mobile-max) {
                        border-radius: 8px;
                    }
                }
            }
        }

        .swiper-pagination {
            bottom: 73px;

            @media (max-width: $desktop-xl-max) {
                bottom: 98px;
            }

            @media (max-width: $desktop-lg-max) {
                bottom: 48px;
            }

            @media (max-width: $desktop-md-max) {
                bottom: 68px;
            }

            @media (max-width: $mobile-max) {
                bottom: 8px;
            }
        }

        .swiper-pagination-bullet {
            width: 28px;
            height: 28px;
            margin-right: 28px !important;
            border-radius: 2px;
            outline: none;
            background: rgba(0, 0, 0, 0.7);
            transition: background-color 0.3s ease-in-out,
            transform 0.3s ease-in-out,
            width 0.3s ease-in-out,
            height 0.3s ease-in-out;

            &:last-child {
                margin-right: 0 !important;
            }

            @media (max-width: $desktop-xxl-max) {
                width: 22px;
                height: 22px;
                margin-right: 22px !important;
                border-radius: 3px;
            }

            @media (max-width: $desktop-xl-max) {
                width: 16px;
                height: 16px;
                margin-right: 16px !important;
                border-radius: 2px;
            }

            @media (max-width: $desktop-md-max) {
                width: 9px;
                height: 9px;
                margin-right: 8px !important;
                border-radius: 1px;
            }

            @media (max-width: $mobile-max) {
                width: 11px;
                height: 11px;
                margin-right: 10px !important;
                border-radius: 1px;
            }

            &.swiper-pagination-bullet-active {
                width: 31px;
                height: 31px;
                transform: rotate(45deg);
                background: $light-blue;

                @media (max-width: $desktop-xxl-max) {
                    width: 26px;
                    height: 26px;
                }

                @media (max-width: $desktop-xl-max) {
                    width: 18px;
                    height: 18px;
                }

                @media (max-width: $desktop-md-max) {
                    width: 10px;
                    height: 10px;
                }

                @media (max-width: $mobile-max) {
                    width: 13px;
                    height: 13px;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 104px;
            height: 104px;
            background: $light-blue;
            backdrop-filter: blur(15px);
            border-radius: 15px;
            outline: none;
            margin: 0;
            transform: translateY(-100%);
            transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;

            @media (max-width: $desktop-xxl-max) {
                width: 85px;
                height: 85px;
            }

            @media (max-width: $desktop-xl-max) {
                width: 60px;
                height: 60px;
                border-radius: 14px;
            }

            @media (max-width: $desktop-lg-max) {
                width: 55px;
                height: 55px;
            }

            @media (max-width: $desktop-md-max) {
                width: 50px;
                height: 50px;
                border-radius: 12px;
            }

            @media (max-width: $mobile-max) {
                width: 30px;
                height: 30px;
                border-radius: 8px;
            }

            &::after {
                display: none;
            }

            svg {
                width: 50px;
                height: 50px;
                fill: #1B74A3;
                transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;

                @media (max-width: $desktop-xxl-max) {
                    width: 40px;
                    height: 40px;
                }

                @media (max-width: $desktop-xl-max) {
                    width: 30px;
                    height: 30px;
                }

                @media (max-width: $desktop-md-max) {
                    width: 24px;
                    height: 24px;
                }
            }

            @media (hover: hover) {
                &:hover {
                    background-color: #fff;
                    transform: translateY(-100%) rotate(45deg);

                    svg {
                        fill: $violet;
                        transform: rotate(-45deg);
                    }
                }
            }

            &:active {
                background-color: #fff;
                transform: translateY(-100%) rotate(45deg);

                svg {
                    fill: $violet;
                    transform: rotate(-45deg);
                }
            }

        }

        .swiper-button-prev {
            left: calc(50% - 903px);
            margin-top: 29px;

            @media (max-width: $desktop-xxl-max) {
                left: calc(50% - 736px);
                margin-top: 18px;
            }

            @media (max-width: $desktop-xl-max) {
                left: calc(50% - 516px);
                margin-top: 5px;
            }

            @media (max-width: $desktop-md-max) {
                left: calc(50% - 285px);
                margin-top: 0;
            }

            @media (max-width: $mobile-max) {
                left: calc(50% - 168px);
                margin-top: 10px;
            }
        }

        .swiper-button-next {
            left: auto;
            right: calc(50% - 903px);
            margin-top: 29px;

            @media (max-width: $desktop-xxl-max) {
                right: calc(50% - 736px);
                margin-top: 18px;
            }

            @media (max-width: $desktop-xl-max) {
                right: calc(50% - 516px);
                margin-top: 5px;
            }

            @media (max-width: $desktop-md-max) {
                right: calc(50% - 285px);
                margin-top: 0;
            }

            @media (max-width: $mobile-max) {
                right: calc(50% - 168px);
                margin-top: 10px;
            }
        }
    }
}
