.landing {

    &__second-screen {
        height: 718px;

        @media (max-width: $desktop-xxl-max) {
            height: 748px;
        }

        @media (max-width: $desktop-xl-max) {
            height: 464px;
        }

        @media (max-width: $desktop-lg-max) {
            height: 505px;
        }

        @media (max-width: $desktop-md-max) {
            height: 293px;
        }

        @media (max-width: $desktop-sm-max) {
            height: 391px;
        }

        @media (max-width: $mobile-max) {
            display: none;
        }

        .landing__container {
            position: relative;
            height: 100%;
        }

        .bubble {
            position: absolute;
            top: -211px;
            left: 0;
            z-index: 2;
            display: flex;
            align-items: center;
            width: 499px;
            height: 127px;
            font-weight: 700;
            font-size: 38px;
            line-height: 50px;
            color: $dark-text;

            @media (max-width: $desktop-xxl-max) {
                width: 424px;
                height: 108px;
                top: -248px;
                font-size: 32px;
                line-height: 42px;
            }

            @media (max-width: $desktop-xl-max) {
                top: -129px;
                width: 300px;
                height: 76px;
                font-size: 24px;
                line-height: 31px;
            }

            @media (max-width: $desktop-lg-max) {
                top: -133px;
            }

            @media (max-width: $desktop-md-max) {
                top: -71px;
                width: 220px;
                height: 56px;
                font-size: 18px;
                line-height: 24px;
            }

            @media (max-width: $desktop-sm-max) {
                top: -8px;
                width: 220px;
                height: 56px;
                font-size: 18px;
                line-height: 24px;
            }

            .text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-transform: uppercase;
                color: inherit;
                transition: color 0.3s ease-in-out;
            }

            &:hover {
                .text {
                    color: $violet;
                }

                svg {
                    fill: #fff;
                }
            }

            &::before {
                content: '';
                position: absolute;
                width: 569px;
                height: 180px;
                left: -38px;
                top: -17px;
                background-image: url('../img/button-bg.png');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                z-index: -1;

                @media (max-width: $desktop-xxl-max) {
                    width: 483px;
                    height: 153px;
                    left: -32px;
                    top: -14px;
                }

                @media (max-width: $desktop-xl-max) {
                    top: -10px;
                    left: -23px;
                    width: 342px;
                    height: 108px;
                }

                @media (max-width: $desktop-lg-max) {
                    top: -10px;
                    left: -23px;
                    width: 342px;
                    height: 108px;
                }

                @media (max-width: $desktop-md-max) {
                    width: 251px;
                    height: 79px;
                    left: -17px;
                    top: -7px;
                }

                @media (max-width: $desktop-sm-max) {
                    width: 251px;
                    height: 79px;
                    left: -17px;
                    top: -8px;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $light-blue;
                transition: fill 0.3s ease-in-out;
            }

            .sub {
                position: absolute;
                left: 0;
                top: calc(100% + 18px);
                text-transform: uppercase;
                font-size: 26px;
                line-height: 34px;
                color: rgba(255, 255, 255, 0.6);


                @media (max-width: $desktop-xl-max) {
                    font-size: 20px;
                    line-height: 26px;
                }

                @media (max-width: $desktop-lg-max) {
                    font-size: 16px;
                    line-height: 20px;
                    top: calc(100% + 10px);
                }

                @media (max-width: $desktop-sm-max) {
                    font-size: 12px;
                    line-height: 16px;
                    top: calc(100% + 14px);
                }
            }
        }

        .cat {
            position: absolute;
            top: -286px;
            left: -1034px;
            width: 1222px;
            height: 1200px;
            background-image: url('../img/astra-cat.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

            @media (max-width: $desktop-xxl-max) {
                top: -158px;
                left: -161px;
                width: 1036px;
                height: 1000px;
            }

            @media (max-width: $desktop-xl-max) {
                top: -56px;
                left: -168px;
                width: 688px;
                height: 666px;
            }

            @media (max-width: $desktop-lg-max) {
                top: -38px;
            }

            @media (max-width: $desktop-md-max) {
                top: -30px;
                left: -65px;
                width: 450px;
                height: 420px;
            }

            @media (max-width: $desktop-sm-max) {
                top: 34px;
                left: 86px;
                width: 384px;
                height: 420px;
            }
        }
    }
}
