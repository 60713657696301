.footer {
    width: 100%;
    height: 500px;
    margin-top: auto;
    background: rgba(37, 37, 37, 0.6);
    backdrop-filter: blur(45px);

    @media (max-width: $desktop-xxl-max) {
        height: 316px;
    }

    @media (max-width: $desktop-xl-max) {
        height: 150px;
    }

    @media (max-width: $desktop-md-max) {
        height: 80px;
    }

    @media (max-width: $mobile-max) {
        height: auto;
    }

    .landing__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        @media (max-width: $mobile-max) {
            flex-direction: column;
            justify-content: center;
            padding: 16px 0 30px;
        }
    }

    &__logo {
        position: relative;
        opacity: 0.3;
        width: 352px;
        height: 210px;

        @media (max-width: $desktop-xxl-max) {
            width: 337px;
            height: 202px;
        }

        @media (max-width: $desktop-xl-max) {
            width: 137px;
            height: 82px;
        }

        @media (max-width: $desktop-md-max) {
            width: 91px;
            height: 55px;
        }

        @media (max-width: $desktop-sm-max) {
            width: 91px;
            height: 55px;
        }

        @media (max-width: $mobile-max) {
            width: 106px;
            height: 64px;
            margin-bottom: 18px;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

        span {
            position: absolute;
            left: 100%;
            top: 20px;
            font-weight: 700;
            font-size: 36px;
            line-height: 46px;

            @media (max-width: $desktop-xl-max) {
                font-size: 14px;
                line-height: 18px;
            }

            @media (max-width: $desktop-md-max) {
                top: 10px;
                font-size: 8px;
                line-height: 8px;
            }

            @media (max-width: $mobile-max) {
                margin-bottom: 18px;
                top: 10px;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    &__info {
        font-size: 48px;
        line-height: 58px;

        @media (max-width: $desktop-xxl-max) {
            font-size: 36px;
            line-height: 44px;
        }

        @media (max-width: $desktop-xl-max) {
            font-size: 24px;
            line-height: 29px;
        }

        @media (max-width: $desktop-md-max) {
            font-size: 16px;
            line-height: 19px;
        }

        @media (max-width: $desktop-sm-max) {
            font-size: 12px;
            line-height: 15px;
        }

        @media (max-width: $mobile-max) {
            font-size: 16px;
            line-height: 19px;
            text-align: center;
        }
    }

    &__email {
        font-weight: 700;
        text-decoration: none;
        outline: none;
        color: #fff;
        font-size: 48px;
        line-height: 62px;

        @media (max-width: $desktop-xl-max) {
            font-size: 24px;
            line-height: 32px;
        }

        @media (max-width: $desktop-md-max) {
            font-size: 16px;
            line-height: 20px;
        }

        @media (max-width: $desktop-sm-max) {
            font-size: 16px;
            line-height: 20px;
        }

        @media (max-width: $mobile-max) {
            margin-bottom: 19px;
            font-size: 16px;
            line-height: 21px;
        }
    }
}
