.dont-know-code-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  justify-content: flex-start;
  padding: 138px 0 0;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);

  &__rect {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__content {
    width: 310px;
    height: 212px;
    padding: 42px 24px 24px;
    border-radius: 12.7869px;
    position: relative;
    margin: 0 auto;
  }

  &__black-rect {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__text,
  &__close {
    position: relative;
  }

  &__text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    span {
      color: rgba(37, 198, 249, 1);
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    padding: 0;
    margin: 23px auto 0;
    border: none;
    background: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #004459;

    span {
      position: relative;
      color: rgba(0, 68, 89, 1);
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &.opened {
    display: flex;
  }
}
