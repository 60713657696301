$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1023px;
$desktop-sm-min: 1024px;
$desktop-sm-max: 1199px;
$desktop-md-min: 1200px;
$desktop-md-max: 1919px;
$desktop-lg-min: 1920px;
$desktop-lg-max: 2559px;
$desktop-xl-min: 2560px;
$desktop-xl-max: 3839px;
$desktop-xxl-min: 3840px;
$desktop-xxl-max: 5119px;
$desktop-xxxl-min: 5120px;

$violet: #800BF5;
$light-blue: #49CBF4;
$dark-text: #004459;
