*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
    padding: 0;
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

a {
    text-decoration: none;
}

/* Make images easier to work with */
img {
    display: block;
    max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
    outline: none;
}


button {
    padding: 0;
    border: none;
    background: none;
}

a {
    color: currentColor;
    font: inherit;
    background-color: transparent;
    outline: none;
}
